import React, { PureComponent, MouseEvent } from 'react'
import Layout from '../components/layout'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { WindowLocation } from '@reach/router'
import Slider from 'react-slick'

import FirstImage from '../images/gallery/hollywood-room/hollywood-room-2.jpg'
import LogoText from '../images/venue-of-hollywood-logo-text.svg'
import FullLogo from '../images/full-logo.svg'

import CarouselImageOne from '../images/gallery/screens-inside/screen-inside-4.jpg'
import CarouselImageTwo from '../images/gallery/live-room/live-room-5.jpg'
import CarouselImageThree from '../images/gallery/live-room/live-room-4.jpg'
import CarouselImageFour from '../images/gallery/hollywood-room/hollywood-room-3.jpg'
import CarouselImageFive from '../images/gallery/live-room/live-room-8.jpg'
import CarouselImageSix from '../images/gallery/screens-outside/screen-outside-1.jpg'

import Modal from '../components/modal'
import Section, {SubSection, Header, SubHeader, Divider} from '../components/section/section'
import TextualCarousel, { DisplayItem } from '../components/textualCarousel'
import Amenities from '../components/amenities'
import theme from '../theme'

const FirstSection = styled.div`
  position: relative;
  display: flex; 
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
`

const VideoSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  max-width: 1440px;
  align-items: center;
  z-index: 5;

  > div {
    z-index: 5;
  }
`

const VideoPlayer = styled.div`
  background: gray;
  max-height: calc(30vw * 9/16);
  height: 100vh;
  max-width: calc(50vw * 9/16);
  width: 100vw;
`

const SpaceImageGrid = styled.div`
  display: grid;
  grid-template-areas: 'a1 a2' 'b1 b2' 'c1 c2';
  grid-gap: 1rem;

  @media(max-width: 600px) {
    grid-template-areas: 'a1' 'a2' 'b1' 'b2' 'c1' 'c2';
  }
`

const SpaceImage = styled.img`
  position: relative;
  height: 250px;
  border-radius: 3px;
  margin: 0; 
  width: 100%;
  object-fit: cover;
  box-shadow: 0px 0px 10px -2px black;

  &:hover {
    cursor: pointer;
  }
`

const SVGWrapper = styled(SVG)`
  width: inherit;
  padding: ${props => props.isMobile ? '40px' : '80px'};
  position: absolute;
  right: 0;
  left: 0;
  z-index: 2;
  max-height: calc(90vw * 4/6);

  svg {
    width: 100%;
    fill: white;
  }
`

const SliderWrapper = styled(Slider)`
  .slick-list {
    .slick-track {
      .slick-slide {
        display: flex;
        justify-content: center;
      }
    }
  }
`

const eventItems = [
  {
    title: 'Pop-up Shops',
    icon: 'store'
  },
  {
    title: 'Premiere/Release Parties',
    icon: 'new_releases'
  },
  {
    title: 'Movie Screenings',
    icon: 'theaters'
  },
  {
    title: 'Record Launches',
    icon: 'album'
  },
  {
    title: 'Listening Parties',
    icon: 'hearing'
  },
  {
    title: 'Concerts & Live Shows',
    icon: 'equalizer'
  },
  {
    title: 'Music Showcases',
    icon: 'music_note'
  },
  {
    title: 'Wedding Receptions',
    icon: 'filter_vintage'
  },
  {
    title: 'Bridal Shows',
    icon: 'view_quilt'
  },
  {
    title: 'Bar/Bat Mitzvahs',
    icon: 'event_seat'
  },
  {
    title: 'Cocktail Hours/Mixers',
    icon: 'local_bar'
  },
  {
    title: 'Photography Shoots',
    icon: 'camera_enhance'
  },
  {
    title: 'Red Carpet Events',
    icon: 'flare'
  },
  {
    title: 'Art Openings',
    icon: 'event'
  },
  {
    title: 'Galleries & Exhibitions',
    icon: 'panorama'
  },
  {
    title: 'Film & TV Productions',
    icon: 'movie'
  },
  {
    title: 'Music Videos',
    icon: 'music_video'
  },
  {
    title: 'Commercial & Private Photo Shoots',
    icon: 'camera'
  },
  {
    title: 'Fashion Shows',
    icon: 'whatshot'
  },
  { 
    title: 'Press Releases',
    icon: 'forum',
  },
  {
    title: 'Corporate Events',
    icon: 'business_center'
  },
  {
    title: 'Fundraisers & Benefit Parties',
    icon: 'loyalty'
  },
  {
    title: 'Product Launches',
    icon: 'grade'
  },
  {
    title: 'Holiday Parties',
    icon: 'weekend'
  },
  {
    title: 'Birthday Parties',
    icon: 'cake'
  },
  {
    title: 'Board Meeting & Conferences',
    icon: 'meeting_room'
  },
  {
    title: 'Film Festival Panels, Screenings & Workshops',
    icon: 'event_seat'
  },
  {
    title: 'Casting Calls',
    icon: 'recent_actors'
  },
  {
    title: 'Auditions',
    icon: 'ballot'
  },
  {
    title: 'Networking Events',
    icon: 'group_add'
  }
]

type Props = {
  location: WindowLocation
}

type State = {
  isMobile: boolean,
  toggledImage: string | null
}

export default class Index extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)

    this.toggleImage = this.toggleImage.bind(this)

    this.state = {
      isMobile: false,
      toggledImage: null
    }
  }

  componentDidMount() {
    this.setState({
      isMobile: typeof window !== 'undefined' && window.innerWidth <= 768
    })
  }

  toggleImage = (event: MouseEvent) => {
    this.setState({
      toggledImage: event.currentTarget ? event.currentTarget.src : null
    })
  }

  render() {
    const modalizedImage = this.state.toggledImage ? (
      <Modal handleClose={this.toggleImage}>
        <img 
          src={this.state.toggledImage} 
          alt="Toggled"
          style={{
            maxWidth: '99vw', 
            maxHeight: '90vh',
            objectFit: 'contain',
            padding: '40px',
            margin: '0'
          }}/>
      </Modal>
    ) : null

    const sliderSettings = {
      dots: false,
      infinite: true,
      autoplaySpeed: 3000,
      autoplay: true,
      arrows: false,
      slidesToShow: this.state.isMobile ? 1 : 3,
      slidesToScroll: 1
    }

    return (
      <Layout location={this.props.location}>
        <FirstSection id="index-top">
          <SVGWrapper src={FullLogo} isMobile={this.state.isMobile} />
          {/* <img src={FullLogo} alt="Logo icon with text" style={{ padding: '40px', margin: '0', position: 'relative', zIndex: 2, objectFit: 'contain', maxHeight: 'calc(90vw * 9/16)' }}/> */}
          <img src={FirstImage} alt="Header" style={{ width: '100%', margin: '0', filter: 'brightness(0.8)' }}/>
        </FirstSection>
        <Section width="100%" margin="0" padding="20px 0" background="whitesmoke">
          <SubSection>
            <Header title="Events"/>
            <SubHeader>
              Located in the heart of Hollywood, at the prestigious Hollywood & Highland Center, the prime location of The Venue of Hollywood offers a unique and versatile event space for the A-list clientele of Hollywood, allowing the hosting of a multitude of events in a sleek and refined environment. Coupled with the state-of-the-art LED screen technology that can be used in a variety of ways to make any event more impactful, The Venue of Hollywood is a top-tier space, perfect for your next event.
            </SubHeader>
            <Divider themeColor={theme.palette.primary.main}/>
            <div style={{ margin: '20px auto' }}>
              <SliderWrapper {...sliderSettings}>
                {
                  eventItems.map(item => (
                    <DisplayItem
                      key={item.title} 
                      title={item.title}
                      icon={item.icon}
                      height="auto" />
                  ))
                }
              </SliderWrapper>
            </div>
          </SubSection>
        </Section>
        <Section width="100%" margin="0 0 40px 0" padding="20px 0" position="relative">
          <SubSection>
            <Header title="The Space"/>
            <SubHeader>
              The Venue of Hollywood is a versatile
              space that can be configured in multiple
              ways to suit your particular event. With
              impressive details and amazing views
              of Hollywood Boulevard, The Venue of
              Hollywood has been the site of album
              release parties, art exhibitions, video
              premieres, musical performances,
              pop-up shops and signings, as well
              as exclusive Hollywood red carpet
              events. It’s the perfect location to
              make your event memorable.
            </SubHeader>
            <Divider themeColor={theme.palette.primary.main}/>
            <SpaceImageGrid>
              <SpaceImage 
                src={CarouselImageOne} 
                onClick={this.toggleImage}
                style={{
                  gridArea: 'a1'
                }} />
              <SpaceImage 
                src={CarouselImageTwo} 
                onClick={this.toggleImage}
                style={{
                  gridArea: 'a2'
                }} />
              <SpaceImage 
                src={CarouselImageThree} 
                onClick={this.toggleImage}
                style={{
                  gridArea: 'b1'
                }} />
              <SpaceImage 
                src={CarouselImageFour} 
                onClick={this.toggleImage}
                style={{
                  gridArea: 'b2'
                }} />
              <SpaceImage 
                src={CarouselImageFive} 
                onClick={this.toggleImage}
                style={{
                  gridArea: 'c1'
                }} />
              <SpaceImage 
                src={CarouselImageSix} 
                onClick={this.toggleImage} 
                style={{
                  gridArea: 'c2'
                }} />
            </SpaceImageGrid>
          </SubSection>
        </Section>
        <Section width="100%" margin="0" position="relative">
          <Amenities />
        </Section>
        {modalizedImage}
      </Layout>
    )
  }
}