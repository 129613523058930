import React, { PureComponent, CSSProperties } from 'react'
import styled from 'styled-components'
import {
  Carousel,
  CarouselItem,
  CarouselIndicators
} from 'reactstrap'
import Typography from '@material-ui/core/Typography'
import SVG from 'react-inlinesvg'

import Theme from '../theme'

const CarouselWrapper = styled(Carousel)`
  &&& {
    display: flex;
    align-items: center;
    min-height: ${props => props.minHeight ? props.minHeight : '400px'};
    flex-direction: column;
    margin-top: 40px;

    .carousel-inner {
      height: 200px;

      @media(max-width: 1220px) {
        height: auto;
      }
    }
  }
`

const Item = styled(CarouselItem)`
  &&& {
    &.active {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: ${props => props.count > 3 ? '100px' : '0'};
    }
  }
`

const Indicators = styled(CarouselIndicators)`
  &&& {      
    position: relative;
    margin: 40px auto 0 auto;

    > li {
      background: rgba(0, 0, 0, 0.2);
      width: 15px;
      height: 15px;
      border-radius: 7.5px;
      display: ${props => props.count > 3 ? 'inline-block' : 'none'};

      &.active {
        background: ${Theme.palette.primary.light};
      }
    }
  }
`

type Props = {
  items: Array<{
    icon: object | string,
    title: string,
    height?: string,
    description?: string,
    isGoogleIcon?: boolean
  }>,
  style?: any
}

type State = {
  activeIndex: number
}

export default class TextualCarousel extends PureComponent<Props, State> {
  private animating: boolean = false

  constructor(props: Props) {
    super(props)

    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.goToIndex = this.goToIndex.bind(this)
    this.onExiting = this.onExiting.bind(this)
    this.onExited = this.onExited.bind(this)

    this.state = { activeIndex: 0 }
  }

  onExiting() {
    this.animating = true
  }

  onExited() {
    this.animating = false
  }

  next() {
    if (this.animating || this.props.items.length <= 3) {
      return
    }

    const nextIndex = this.state.activeIndex === Math.ceil(this.props.items.length / 3) - 1 ? 0 : this.state.activeIndex + 1
    this.setState({ activeIndex: nextIndex })
  }

  previous() {
    if (this.animating || this.props.items.length <= 3) {
      return
    }

    const nextIndex = this.state.activeIndex === 0 ? Math.ceil(this.props.items.length / 3) - 1 : this.state.activeIndex - 1
    this.setState({ activeIndex: nextIndex })
  }

  goToIndex(newIndex: number) {
    if (this.animating || this.props.items.length <= 3) {
      return
    }

    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state

    const tempSlides = this.props.items.map((item) => {
      return (
        <DisplayItem 
          key={item.title}
          icon={item.icon || {}}
          title={item.title} 
          height={item.height || '100%'}
          description={item.description}
          isGoogleIcon={item.isGoogleIcon} />
      )
    })

    let slides = []
    let counter = 0

    while (tempSlides.length) {
      slides.push(
        <Item
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={counter}>
          {tempSlides.splice(0, 3)}
        </Item>
      )

      counter++
    }

    return (
      <CarouselWrapper
        {...this.props}
        activeIndex={activeIndex}
        next={this.next}
        previous={this.previous}
        ride="carousel"
        style={this.props.style}>
        {slides}
        <Indicators 
          count={this.props.items.length}
          items={slides} 
          activeIndex={activeIndex} 
          onClickHandler={this.goToIndex} />
      </CarouselWrapper>
    )
  }
}

const DisplayItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 250px;
  margin: 20px;
`

const DisplayItemIcon = styled.i<CSSProperties>`
  color: ${props => props.color ? props.color : 'black'};
  background: ${props => props.background ? props.background : 'transparent'};
  font-size: 36px !important;
  color: ${props => props.color ? props.color : Theme.palette.primary.main};
  margin: 0 auto 20px auto;
`

const DisplayItemImage = styled(SVG)`
  & {    
    width: ${props => props.large ? '48px' : '36px'};
    height: ${props => props.large ? '48px' : '36px'};
    margin: 0 auto 20px auto;
    fill: white;
  }
`

const DisplayItemTextWrapper = styled.div<CSSProperties>`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
`

export const DisplayItem = ({title = '', description = '', icon = ({} || ''), isGoogleIcon = true, color = '', large = false, height = '100%'}) => {
  const iconEl = (typeof icon === 'string' && icon.length > 0 && isGoogleIcon) ? <DisplayItemIcon color={color} className="material-icons">{icon}</DisplayItemIcon> : (typeof icon === 'string' && icon.length > 0 && !isGoogleIcon) ? <DisplayItemImage src={icon} large={large}/> : null

  return(
    <DisplayItemWrapper>
      {iconEl}
      <DisplayItemTextWrapper height={height}>
        <Typography variant="body1" style={{color: color.length > 0 ? color : 'black'}}>{title}</Typography>
        <Typography variant="body2" style={{color: color.length > 0 ? color : 'black', fontWeight: 300}}>{description}</Typography>
      </DisplayItemTextWrapper>
    </DisplayItemWrapper>
  )
}