import React, { SFC } from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import SVG from 'react-inlinesvg'

import Theme from '../theme'
import { Header, SubHeader, Divider } from './section/section'

import TieIcon from '../images/icons/tie.svg'
import ArchiveIcon from '../images/icons/archive.svg'
import SofaIcon from '../images/icons/sofa-white.svg'
import TrackLightIcon from '../images/icons/track-light.svg'

const AmenitiesWrapper = styled.div`
  background: ${Theme.palette.primary.main};
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding: 40px 0;
`

const BottomPoint = styled.div`
  background: ${Theme.palette.primary.main};
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: -25px;
  left: calc(50% - 25px);
  transform: rotateZ(45deg);
  z-index: 1;
`

type Amenity = {
  name: string,
  icon: string,
  description?: string,
  isGoogle: boolean
}

const amenitiesList = [
  {
    name: 'On-site Manager and Rep',
    icon: 'assignment_ind',
    description: 'To assist in arranging the details of your event and make sure everything goes smoothly, from start to finish.',
    isGoogle: true
  },
  {
    name: 'VIP Green Room with Makeup Vanities',
    icon: TieIcon,
    isGoogle: false
  },
  {
    name: 'Red Carpet & Equipment for Step and Repeat',
    icon: ArchiveIcon,
    description: 'A glamorous entrance for your event guests, set up right outside the doors to The Venue.',
    isGoogle: false
  },
  {
    name: 'Luxury Furniture',
    icon: SofaIcon,
    description: 'An assortment of furniture that can be arranged to make your guests comfortable.',
    isGoogle: false
  },
  {
    name: 'Cinematic and Ambient Concert Lighting',
    icon: TrackLightIcon,
    description: 'So you can set that “just right” mood for your performance.',
    isGoogle: false
  },
  {
    name: 'Chandeliers on Dimmers',
    icon: 'brightness_medium',
    description: 'Making sure your event is always seen in the right light.',
    isGoogle: true
  },
  {
    name: 'Gallery Lighting on Artwork',
    icon: 'lightbulb',
    isGoogle: true
  },
  {
    name: '7 Level Underground Parking',
    icon: 'local_parking',
    description: 'Convenient parking for all of your guests.',
    isGoogle: true
  },
  {
    name: 'Guest Wifi',
    icon: 'wifi',
    isGoogle: true
  },
  {
    name: 'Refrigerator & Ice Freezer',
    icon: 'kitchen',
    isGoogle: true
  },
  {
    name: 'Two Movable Bars',
    icon: 'local_bar',
    description: '32” x 122” and 32” x 96”',
    isGoogle: true
  },
  {
    name: 'Loading Dock to H&H Complex',
    icon: 'local_shipping',
    description: 'Convenient entrance to unload anything from catering to bar supplies to band equipment and easily transport to our space.',
    isGoogle: true
  },
  {
    name: 'Handicap Accessible Restrooms',
    icon: 'accessible',
    isGoogle: true
  }
]

const Amenities: SFC = () => (
  <AmenitiesWrapper>
    <Header title="Amenities" themeColor="white"/>
    <SubHeader themeColor="white">
      Regardless of the size or type of
      event you’re planning, we have all
      the elements to make it successful.
      From staffing to lighting to audio/
      visual, The Venue of Hollywood
      can make it happen. Make sure to
      inquire regarding any specific ideas
      you might have when it comes to
      your particular event.
    </SubHeader>
    <Divider/>
    <AmenitiesList items={amenitiesList}/>
    <BottomPoint />
  </AmenitiesWrapper>
)

type AmenitiesListProps = {
  items: Amenity[]
}

const AmenitiesList = (props: AmenitiesListProps) => {
  const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  `

  return(
    <Wrapper>
      {props.items.map((item, index) => <Amenity key={`${index}-${item.name}`} {...item}/>)}
    </Wrapper>
  )
}
  
const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ImageEl = styled(SVG)`
  &&& {
    svg {
      margin: 0;
      width: 36px;
      height: 36px;
    }
  }
`

const Image = (props: { src: string }) => (
  <ImageWrapper>
    <ImageEl src={props.src} />
  </ImageWrapper>
)

const AmenityWrapper = styled.div`
  width: 350px;
  height: 200px;
  display: flex;
  align-items: end;
  justify-content: space-between;
  padding: 20px;
  margin: 10px;

  @media(max-width: 768px) {
    width: 100%;
  }
`

const AmenityIcon = styled.i`
  font-size: 36px;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
`

const AmenityTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  width: 100%;
  height: 100%;
`


const Amenity = (props: Amenity) => {  
  const item = props.isGoogle ? <AmenityIcon className="material-icons" color="white">{props.icon}</AmenityIcon> : <Image src={props.icon} />

  return(
    <AmenityWrapper>
      {item}
      <AmenityTextWrapper>
        <Typography variant="body1" style={{color: 'white'}}>{props.name}</Typography>
        <Typography variant="body2" style={{color: 'white', fontWeight: 100}}>{props.description}</Typography>
      </AmenityTextWrapper>
    </AmenityWrapper>
  )
}

export default Amenities